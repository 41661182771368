import React, { VFC } from "react"

export interface NotFoundProps {
  content?: React.ReactNode
}

const NotFound: VFC<NotFoundProps> = ({ content }) => {
  return <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">{content}</div>
}

NotFound.defaultProps = {
  content: "Not Found",
}

export default NotFound
