import React from "react"
import { Helmet } from "react-helmet"
import AnonymousLayout from "../components/layout/AnonymousLayout"
import NotFound from "../components/NotFound"

const NotFoundPage = () => {
  return (
    <AnonymousLayout>
      <Helmet>
        <title>404 - GoFight | KMITL</title>
      </Helmet>
      <NotFound />
    </AnonymousLayout>
  )
}

export default NotFoundPage
