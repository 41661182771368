import React, { FC } from "react"
import KmitlLogo from "../../images/logo-go.png"

const AnonymousLayout: FC = ({ children }) => {
  return (
    <div className="min-h-screen bg-white">
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <a href="/">
                  <img
                    className="block lg:hidden h-12 w-auto"
                    src={KmitlLogo}
                    alt="KMITL"
                  />
                </a>
                <a href="/">
                  <img
                    className="hidden lg:block h-12 w-auto"
                    src={KmitlLogo}
                    alt="KMITL"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="py-10">{children}</div>
    </div>
  )
}

export default AnonymousLayout
